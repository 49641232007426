.dissmissable-block {
	position: relative;
	background: var(--theme-ui-background-color);
	padding: var(--p-rythm-sm);
	margin-top: var(--p-rythm-sm);
	&.is-hidden {
		display: none;
	}
	&--close {
		position: absolute;
		top: calc(var(--p-rythm-sm) / 2);
		right: calc(var(--p-rythm-sm) / 2);
		height: 13px;
		display: flex;
		align-items: center;
		width: auto;
		font-size: var(--fs-ui-sm);
		color: var(--theme-accent-color);
		svg {
			margin-left: 0.5em;
			width: 13px;
			height: 13px;
		}
		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}
	.richtext {
		svg {
			color: var(--theme-accent-color);
		}
	}
}

@include from-sm {
	.dissmissable-block {
		&--close {
			top: $padding-rythm-sm;
			right: $padding-rythm-sm;
		}
	}
}

@include from-md {
	.dissmissable-block {
		display: inline-block;
		padding-right: var(--p-rythm-lg);
	}
}
