$control-size: 24px;

.form-boolean {
	input {
		@include visuallyhidden();
	}
	&--label {
		position: relative;
		padding-left: $control-size + 10px;
		display: block;
		&:before,
		&:after {
			content: "";
			width: $control-size;
			height: $control-size;
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -#{$control-size*0.5};
		}
		&:before {
			border: 3px solid var(--theme-accent-color);
		}
		&:after {
			transition: transform 200ms ease-in-out;
			background: var(--theme-accent-color);
			transform: scale(0);
		}
		&:hover {
			&:after {
				transform: scale(0.3);
			}
		}
	}
	input[type="radio"] + &--label {
		&:before,
		&:after {
			border-radius: 100%;
		}
	}
	input:checked + &--label {
		&:after {
			transform: scale(0.55);
		}
	}
}

.form-boolean-group {
	border: 0 none;
	padding: 0;
	&--label {
		display: block;
		margin-bottom: 12px;
		font-weight: bold;
	}
}

@include to($bp-medium) {
	.form-boolean-group {
		.form-boolean {
			+ .form-boolean {
				margin-top: 15px;
			}
		}
	}
}

@include from($bp-medium) {
	.form-boolean-group {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: $padding-rythm;
		grid-row-gap: $padding-rythm-sm;
		&--label {
			grid-column: 1 / 3;
		}
	}
}
