.l-navigation {
	&--item {
		display: block;
		line-height: 1;
		font-size: var(--fs-menu-text);
	}
	&--link {
		svg {
			color: var(--theme-accent-color);
			width: 12px;
			height: auto;
			margin-left: 10px;
		}
	}
}

@include to-md {
	%mobile-menu {
		transition: transform 400ms ease-in-out;
		height: 100vh;
		width: 100%;
		padding: var(--page-gutter);
		position: fixed;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		transform: translateX(100%);
		overflow-y: scroll;

		.orientation-changing & {
			transition: none;
		}
	}

	.l-navigation {
		@extend %mobile-menu;
		z-index: $zi-layer-nav;
		background: var(--theme-page-background-color);
		overflow-x: hidden;
		&.is-active {
			transform: translateX(0);
		}
		&--item {
			position: relative;
		}

		&--link {
			font-size: calc(var(--fs-body) * 1.5);
			cursor: pointer;
			svg {
				margin-bottom: 4px;
				transform: rotate(-90deg);
			}

			.theme-fest23 & {
				color: var(--theme-accent-color);
			}
		}

		&--item + &--item {
			margin-top: calc(var(--p-rythm) / 3);
		}
	}
}

@include from-md {
	.l-navigation {
		$c: &;
		flex-direction: row;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: auto;
		height: var(--header-height);

		&--item {
			margin: 0;

			&:after {
				bottom: -5px;
			}

			&:hover {
				.l-navigation--link {
					&:after {
						opacity: 1;
					}
				}
				.submenu {
					opacity: 1;
					pointer-events: all;
				}
			}
		}

		&--link {
			@include nav-link-hover;
			position: relative;
			z-index: 4;
			text-transform: uppercase;

			&:not(a) {
				cursor: default;
			}
			.theme-fest23 & {
				color: var(--theme-accent-color);
			}
		}

		&--item + &--item {
			margin-left: var(--p-rythm);
		}

		&.has-open-submenu {
			.theme-fest23 & {
				#{$c}--link {
					color: var(--theme-page-background-color);
					svg {
						color: var(--theme-page-background-color);
					}
					&:after {
						background: var(--theme-page-background-color);
					}
				}
			}
		}
	}
}
