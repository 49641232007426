.featured-article {
	$c: &;

	display: flex;
	flex-direction: column;

	&--title {
		@include base-heading;
		align-self: flex-start;
		line-height: 1.5;
		transition: color 200ms ease-in;
	}
	&--metadata {
		font-size: var(--fs-ui-sm);
		color: var(--theme-accent-color);
		text-transform: uppercase;
		span + span {
			margin-left: 1em;
		}
	}
	&:hover {
		color: var(--theme-accent-color);
	}

	@include base-tailings-hover($c);
}

@include to-md {
	.featured-article {
		&--cover {
			margin-top: var(--p-rythm-sm);
		}
		&--cover-image {
			&:nth-child(2),
			&:nth-child(3) {
				display: none;
			}
		}
	}
}

@include from-md {
	.featured-article {
		$c: &;
		&--cover {
			display: flex;
			order: 1;
			margin-bottom: var(--p-rythm-sm);
			&-image {
				&:nth-child(1) {
					flex-basis: 100%;
				}
				&:nth-child(2),
				&:nth-child(3) {
					flex-basis: 25%;
					min-width: 25%;
				}
			}
		}
		&--cover {
			margin-top: calc(var(--p-rythm) * 0.65);
		}
		&--metadata {
			margin-top: calc(var(--p-rythm) * 0.1);
		}
	}
}
