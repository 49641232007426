.sponsor-list {
	display: flex;
	justify-content: space-between;
	&--single {
		display: block;
	}
	&--cover {
		display: block;
	}
}

@include to-md {
	.sponsor-list {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: var(--p-rythm);
		&--single {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&--cover {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		img {
			height: auto;
			width: max(calc((1.2 - var(--logo-as)) * 100%), 40%);
		}
	}
}

@include from-md {
	.sponsor-list {
		$c: #{&};
		img {
			width: auto;
			height: clamp(55px, 3.9vw, 75px);
		}
		&__align-left {
			justify-content: flex-start;
			#{$c}--single + #{$c}--single {
				margin-left: var(--p-rythm);
			}
		}
	}
}
