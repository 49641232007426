.btn {
	margin: 0;
	box-shadow: none;
	outline: none;
	appearance: none;

	transition: 200ms ease-in-out;
	position: relative;
	color: var(--theme-text-color);
	padding: var(--p-rythm-sm);
	font-size: var(--fs-subheading);
	line-height: 0.5;
	text-align: center;
	display: block;
	border: 2px solid var(--theme-accent-color);

	&:hover,
	&:focus,
	&:active {
		background: var(--theme-accent-color);
		color: var(--theme-page-background-color);
		cursor: pointer;
	}
}

.btn-list {
	.btn {
		width: 100%;
	}

	.btn + .btn {
		margin-top: $padding-rythm-sm;
	}
}

.btn-group {
	display: flex;

	.btn {
		flex: 1;
	}

	.btn + .btn {
		margin-left: $padding-rythm-sm;
	}
}

@include from($bp-medium) {
	.btn-list {
		display: flex;
		.btn {
			width: auto;
			flex: 1;
		}

		.btn + .btn {
			margin-top: 0;
			margin-left: $padding-rythm-sm;
		}
	}
}
