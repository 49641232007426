.form-select {
	@include base-field;

	&--container {
		position: relative;
		&-arrow {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 12px;
			height: auto;
		}
	}
}
