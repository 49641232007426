@mixin block-base-sm() {
	background: var(--theme-ui-background-color);
	padding: var(--p-rythm);
}

@mixin block-base() {
	background: var(--theme-ui-background-color);
	padding: var(--p-rythm-sm);
}

@mixin base-heading() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1.2;
	font-family: $heading-font;
	margin: 0;
	font-weight: normal;
	font-size: var(--fs-heading);

	.theme-fest23 & {
		color: var(--theme-accent-color);
	}
}

@mixin base-link() {
	transition: border-color 150ms ease-in;
	color: inherit;
	display: inline-block;
	position: relative;
	border-bottom: 2px solid var(--theme-accent-color);

	&:hover,
	&:focus,
	&:active {
		border-bottom-color: var(--theme-accent-color-light);
	}
}

@mixin base-tailings-hover($component-base) {
	.theme-tailings & {
		#{$component-base}--title {
			transition: border-bottom-color 150ms ease-in-out;
			display: inline;
			border-bottom: 2px solid transparent;
		}

		&:hover {
			#{$component-base}--title {
				color: var(--theme-text-color);
				border-bottom-color: #333333;
			}
		}
	}
}

@mixin base-field() {
	position: relative;
	width: 100%;

	&--field,
	&--label {
		display: block;

		line-height: 1.3;
	}
	&--field {
		border: 0 none;
		padding: 0;
		padding-bottom: 10px;
		width: 100%;
		appearance: none;
		border-bottom: 2px solid var(--theme-text-color);
		color: var(--theme-text-color);
		background: none;
		box-shadow: none;
		outline: none;

		&:focus,
		&[value]:not([value=""]),
		&.is-focused {
			outline: none;
		}

		&.is-invalid {
			~ .form-field--error-messages {
				display: block;
			}
		}
		&:invalid {
			// Overide browser defaults. We use classes as below (for browser compat and UX reasons)
			outline: 0 none;
			box-shadow: none;
		}
	}
	&--label {
		margin-bottom: 1em;
		font-weight: bold;
		&-required {
			margin-left: 3px;
			color: red;
		}
	}

	&--error-messages {
		color: red;
		width: 100%;
		&-single {
			margin-top: 10px;
		}
	}

	&--help {
		width: 100%;
		padding: 12px;
		padding-left: 0;
		font-size: 0.8em;
		opacity: 0.8;
	}
}
