.l-map-picker {
	display: none;
	position: relative;
	&--content {
		position: absolute;
		z-index: 1;
		top: $padding-rythm;
		left: $padding-rythm;
	}
	&--title {
		@include base-heading;
		color: black;
		margin-top: 0;
		margin-bottom: 50px;
		font-size: $fs-h1-sm;
	}
	&--navigation {
		&-item {
			padding: $padding-rythm-sm 0;
			position: relative;
			margin-right: $padding-rythm-sm;
			&:after {
				transition: opacity 300ms ease-in, transform 300ms ease-in;
				content: "";
				position: absolute;
				width: 100%;
				height: 3px;
				background: var(--theme-accent-color);
				bottom: $padding-rythm-sm - 5px;
				left: 0;
				opacity: 0;
				transform: translateY(3px);
			}
			&:hover,
			&.is-active {
				&:after {
					opacity: 1;
					transform: translateY(0);
				}
			}
		}
	}
	&--maps {
		&-single {
			width: 100%;
			left: 0;
			display: none;

			&.is-active {
				display: block;
			}
		}
	}
}

@include from($bp-medium) {
	.l-map-picker {
		display: block;
		&--title {
			font-size: $fs-h1;
		}
	}
}
