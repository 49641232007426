.l-contact {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: var(--p-rythm);
	&--title {
		@include base-heading;
		margin-top: 0;
		font-size: var(--fs-heading);
	}
	.l-cm-subscribe--title,
	&--title {
		margin-bottom: var(--p-rythm-sm);
	}
}

@include from-md {
	.l-contact {
		display: flex;
		justify-content: space-between;
		&--title {
			margin-right: auto;
		}
		.l-cm-subscribe--title,
		&--title {
			margin-bottom: var(--p-rythm);
		}
		&--visit {
			grid-area: contact;
			display: flex;
			justify-content: flex-end;
			.site-link {
				max-width: 450px;
				&--inner {
					line-height: 1.2;
					padding: 0;
				}
			}
		}
		&--visit {
			grid-area: contact;
			display: flex;
			justify-content: flex-end;
			.site-link {
				max-width: 450px;
				&--inner {
					line-height: 1.2;
					padding: 0;
				}
			}
		}
		&--contact {
			grid-area: contact;
		}
		&--form {
			width: 50%;
			grid-area: form;
		}
	}
}
