@mixin nav-link-hover {

    &:after {
        transition: all 200ms ease-in;
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background: var(--theme-accent-color);
        left: 0;
        bottom: -5px;
        opacity: 0;
        transform: translateY(3px);
    }

	&:hover {
		&:after {
            opacity: 1;
            transform: translateY(0);
        }
	}
}