.block-image {
	position: relative;
	background: var(--theme-ui-background-color);

	&--video-cover {
		transition: $transition-default;
		z-index: 3;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.7;
		background: none;
		outline: none;
		display: block;
		border: 0 none;
		&:after {
			content: "";

			position: absolute;
			top: 50%;
			left: 50%;

			width: 0;
			height: 0;
			border-top: 35px solid transparent;
			border-bottom: 35px solid transparent;

			border-left: 70px solid white;

			text-decoration: none;

			transform: translate(-50%, -50%) translateZ(0);
		}

		&:hover,
		&:focus {
			opacity: 1;
			cursor: pointer;
		}
	}
	&--text-overlay {
		z-index: 3;
		position: absolute;
		padding: 0 var(--page-gutter);
		height: 100%;
		top: 0;
		left: 0;
		color: var(--theme-page-background-color);
		display: flex;
		align-items: center;
		font-size: var(--fs-block-text-overlay);
	}
	&--item {
		display: block;
		width: 100%;
		position: relative;
		z-index: 2;
		transition: $transition-default;
		video {
			display: block;
			width: 100%;
			height: auto;
		}
	}
	iframe {
		z-index: 1;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: black;
	}

	&.has-video &--item,
	&.has-video &--video-cover {
		opacity: 0;
		pointer-events: none;
	}
}

@include from($bp-small) {
	.block-image {
		display: flex;
		align-items: center;
		&--item {
			position: relative;
			flex-basis: 100%;
			canvas {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
		&__double {
			display: grid;
			grid-template-columns: 1fr 2fr;
		}
		&__triple {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
}

@include from(1600px) {
	.block-image {
		&--text-overlay {
			padding-right: 25%;
		}
	}
}
