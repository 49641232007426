.captioned-media {
	margin: 0;
	&--asset {
		width: 100%;
		height: auto;
		display: block;
	}
	&--caption {
		padding-top: calc(var(--p-rythm-sm) / 2);
		margin: 0 auto;
		font-size: var(--fs-ui);
	}
}
