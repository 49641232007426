.plain-text {
	font-size: var(--fs-body);
	font-family: $body-font;
	line-height: 1.4;
	color: var(--theme-text-color);
	&__italic {
		font-style: italic;
	}
	&__center {
		text-align: center;
	}
	&__indent {
		padding-left: 2em;
	}
}
