.l-sitewide-banner {
	// These colors are temporary for the 2023 festival (instead of pulling all the festival colors into the main site config just for this component).
	--theme-ui-background-color: #000000;
	--theme-accent-color: #ff5f1f;

	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background: var(--theme-ui-background-color);
	z-index: 20;

	padding: var(--p-rythm-sm) 0;

	&--inner {
		display: block;
		max-width: var(--page-width);
		margin: 0 auto;
	}

	.site-link {
		color: var(--theme-accent-color);
		&--inner {
		}
		&:focus,
		&:active,
		&:hover,
		&.is-active {
			color: white;
		}
	}
}

@include from-md {
	.l-sitewide-banner {
		.site-link--inner {
			font-size: var(--fs-heading);
			line-height: 0.9;
		}
	}
}
