.l-form {
	--form-gap: calc(var(--p-rythm) * 1.1);
	$c: &;
	margin-top: var(--p-rythm-sm);

	&--title {
		font-size: var(--fs-subheading);
		font-weight: normal;
	}

	&--section + &--section,
	&--section + .btn {
		margin-top: calc(var(--section-gap) / 2);
	}

	&--title,
	.stripe-card,
	.form-choice-buttons,
	.form-select,
	.form-field,
	.site-link {
		+ #{$c}--title,
		+ .stripe-card,
		+ .form-choice-buttons,
		+ .form-select,
		+ .form-field,
		+ .site-link {
			margin-top: var(--form-gap);
		}
	}
}

@include from-md {
	.l-form {
		--form-gap: var(--p-rythm);
	}
}
