body {
	.l-sitewide-banner,
	#barba-wrapper {
		transition: opacity 1700ms ease-in-out;
		transition-delay: 500ms;
		opacity: 0;
		pointer-events: none;
	}
	&.animate-overlay-out {
		.l-sitewide-banner,
		#barba-wrapper {
			opacity: 1;
			pointer-events: all;
		}
	}
}

.l-acknowledgement-overlay {
	$c: &;

	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	width: 100vw;
	height: 100vh;
	justify-content: center;
	align-items: center;
	padding: var(--p-rythm);

	transition: 1000ms ease-in-out;
	transition-delay: 800ms;

	opacity: 0;

	&:after {
		content: "";
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		opacity: 0.5;
		background: var(--theme-page-background-color);
	}

	&--close {
		z-index: 1;
		color: var(--theme-text-color);
		font-size: var(--fs-menu-text);
		position: absolute;
		top: var(--page-gutter);
		right: var(--page-gutter);
		height: 20px;
		width: 100px;
		display: inline-flex;
		align-items: center;
		justify-content: flex-end;
		svg {
			margin-right: 10px;
			width: auto;
			height: 100%;
		}

		&:hover,
		&:focus,
		&:active {
			cursor: pointer;
			text-decoration: underline;
		}
	}

	&--copy {
		z-index: 1;
		position: relative;
		font-size: var(--fs-heading);

		* {
			transition: 200ms ease-in-out;
		}
		.rich-text p {
			line-height: 1.4;
		}

		@include from-md {
			font-size: var(--fs-big-heading);
		}
	}

	display: none;
	pointer-events: none;

	.animate-overlay-setup & {
		display: flex;
		pointer-events: all;
	}

	.animate-overlay-in & {
		opacity: 1;
		#{$c}--copy {
			p {
				opacity: 1;
				transform: translateY(0);
				&:nth-child(2) {
					transition-delay: 500ms;
				}
			}
		}
	}
	.animate-overlay-out & {
		opacity: 0;
		pointer-events: none;
	}

	.theme-fest23 & {
		color: var(--theme-accent-color);
	}
}
