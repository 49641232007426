@use "sass:math";

.submenu {
	background-color: var(--theme-page-background-color);

	&--column {
		&-title {
			margin-bottom: $padding-rythm-sm;
			color: var(--theme-accent-color);
			text-transform: uppercase;
			font-size: var(--fs-ui-sm);
		}
	}

	&--column .rich-text,
	&--links .boolean-pill,
	&--links a {
		font-size: calc(var(--fs-body) * 1.5);
	}

	&--links a {
		line-height: 1.2;
	}

	.theme-fest21 & {
		background-color: var(--theme-ui-background-color);
	}

	@include to-md {
		$c: &;
		// navigation.scss
		@extend %mobile-menu;
		z-index: 10;
		&.is-active {
			transform: translateX(0);
		}

		&--back {
			color: var(--theme-text-color);
			svg {
				color: var(--theme-accent-color);
				width: 12px;
				height: auto;
				margin-right: 10px;
				margin-bottom: 2px;
				margin-left: -2px;
				transform: rotate(-270deg);
			}
		}

		&--image {
			display: none;
			&__static {
				display: block;
			}
		}
		&--links {
			li + li {
				margin-top: calc(var(--p-rythm) / 3);
			}
		}

		&--column {
			margin-top: var(--p-rythm);
		}
	}
	@include from-md {
		$c: &;

		top: 0;
		right: 0;
		left: auto;
		position: absolute;
		z-index: 3;
		width: 100vw;

		padding: var(--p-rythm) calc((100vw - var(--page-width)) / 2);
		padding-top: $padding-rythm * 4;
		transform: none;
		display: flex;
		justify-content: flex-end;

		transition: 150ms ease-in-out;
		opacity: 0;
		pointer-events: none;

		&--column {
			width: calc(25% - #{$padding-rythm + math.div($padding-rythm, 3)});
		}

		&--column .rich-text,
		&--links .boolean-pill,
		&--links a {
			font-size: var(--fs-body);
			margin-bottom: calc(var(--p-rythm-sm) / 2);
		}

		&--column + &--column {
			margin-left: $padding-rythm;
		}

		&--links {
			a {
				width: auto;
				display: inline-block;
				position: relative;
				@include nav-link-hover;
				&:after {
					top: calc(100% + 2px);
				}
			}
		}

		&--back {
			display: none;
		}

		&--image {
			position: relative;

			> img {
				position: absolute;
				left: 0;
				bottom: 0;
				opacity: 0;
				transition: opacity 300ms ease-in-out;

				&.is-active {
					opacity: 1;
				}
			}
			&__static {
				> img {
					position: relative;
					opacity: 1;
				}
			}
		}

		.theme-fest23 & {
			background-color: var(--theme-accent-color);
			color: var(--theme-page-background-color);
			width: auto;
			min-width: var(--subnav-width);
			#{$c}--column {
				width: calc(
					var(--page-width) / 4 - #{$padding-rythm + math.div($padding-rythm, 3)}
				);
				&-title {
					color: var(--theme-page-background-color);
				}
			}
			#{$c}--links {
				a {
					&:after {
						background: var(--theme-page-background-color);
					}
				}
			}
		}
	}
}
