.l-site-header {
	&:after {
		transition: opacity 300ms ease-in-out;
		content: "";
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		height: 45vh;
		max-height: 40vw;
		width: 100%;
		background: var(--theme-page-top-gradient);
		pointer-events: none;
	}

	&--inner {
		transition: opacity 300ms ease-out;
		width: var(--page-width);
		margin: 0 auto;
		padding: var(--page-gutter) 0;
	}

	&--logo {
		display: block;
		width: 65%;
		max-width: 300px;
		position: relative;
		z-index: 4;
		opacity: 0;

		&-img,
		svg {
			height: auto;
			width: 100%;
			display: block;
			&__custom {
				height: 40px;
				width: auto;
			}
		}

		svg {
			* {
				color: var(--theme-logo-color);
			}
		}

		&-text {
			white-space: nowrap;
			height: 40px;
			font-family: "industry", sans-serif;
			text-transform: uppercase;
			font-size: var(--fs-text-logo);
		}
	}

	&--trigger {
		position: absolute;
		z-index: 5;
		top: var(--page-gutter);
		right: var(--page-gutter);
		width: 20px;
		height: 20px;
		z-index: $zi-layer-nav + 1;
	}

	&--unco-link {
		position: relative;
		z-index: 4;
		text-transform: uppercase;
		font-size: var(--fs-menu-text);
		margin-top: var(--p-rythm);
		width: 100%;
		.site-link--inner {
			width: auto;
		}
		.site-link--arrow {
			margin-left: calc(var(--p-rythm-sm) / 2);
			width: 170px;
		}
	}

	.theme-tailings & {
		// Header gradient should sit on top of content for Tailings, but not for the main site -JS
		position: relative;
		z-index: 3;
		&:after {
			top: var(--header-height);
			background: linear-gradient(to bottom, rgba(white, 1), rgba(white, 0));
		}
	}

	.theme-tailings.has-video-playing & {
		&:after {
			opacity: 0;
		}
	}

	.theme-fest21 & {
		&:after {
			content: none;
		}
	}

	.theme-fest23 & {
		&:after {
			content: none;
		}
	}

	.theme-a11y & {
		--theme-logo-color: black;
	}

	.has-minimal-header & {
		.l-site-header {
			&--logo {
				opacity: 1;
			}
		}
	}
}

@include from-sm {
	.l-site-header {
		&--inner {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
}

@include from-md {
	.l-site-header {
		&--inner {
			padding: 0;
		}

		&--trigger {
			display: none;
		}
		&--unco-link {
			margin-top: 0;
			margin-left: var(--p-rythm);
			max-width: 400px;
			width: auto;
		}
		&--logo {
			&-text {
				height: auto;
			}
		}
	}
}
