.social-bar {
	$c: &;
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: space-between;
	&--item {
		display: block;
		margin: 0;
	}
	&--item + &--item {
		margin-left: var(--p-rythm);
	}
	&--link {
		transition: border-color 150ms ease-in;
		display: block;
		width: var(--form-control-height);
		height: var(--form-control-height);
		border: 2px solid var(--theme-text-color);
		color: var(--theme-text-color);
		padding: var(--form-control-padding);
		svg {
			width: 100%;
			height: auto;
			display: block;
			color: var(--theme-text-color);
			path {
				transition: fill 300ms ease-in-out;
			}
		}
		&:hover,
		&:focus {
			border-color: var(--theme-accent-color);
		}
	}

	.theme-fest23 & {
		color: var(--theme-accent-color);
		#{$c}--link {
			border-color: var(--theme-accent-color);
			svg {
				color: var(--theme-accent-color);
			}
			&:hover,
			&:focus {
				border-color: var(--theme-text-color);
			}
		}
	}
}

@include from-md {
	.social-bar {
		justify-content: space-between;
	}
}
