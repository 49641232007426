.l-card-list {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-areas:
		"title"
		"cards"
		"link";
	&--title {
		@include base-heading;
		grid-area: title;
		line-height: 0.8;
		font-size: var(--fs-heading);
	}
	&--link {
		grid-area: link;
		margin-top: var(--p-rythm);
	}
	&--cards {
		grid-area: cards;
		display: grid;
		row-gap: var(--p-rythm);
		grid-template-columns: 1fr;
		margin-top: var(--p-rythm);
		&:first-child {
			margin-top: 0;
		}
	}
	&--breadcrumbs {
		margin-top: var(--p-rythm-lg);
		display: flex;
		justify-content: space-between;
		.site-link {
			display: inline-flex;
			width: auto;
		}
	}
}

@include from-md {
	.l-card-list {
		$c: &;
		grid-template-areas:
			"title title link link"
			"cards cards cards cards";
		grid-template-columns: repeat(4, 1fr);
		&--title {
		}
		&--link {
			display: flex;
			justify-content: flex-end;
			margin-top: 0;
		}
		&--cards {
			row-gap: var(--p-rythm-lg);
			grid-template-columns: repeat(4, 1fr);
			&__x2 {
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}
}
