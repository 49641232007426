.positioning-statement {
	font-size: var(--fs-heading);

	.theme-fest23 & {
		color: var(--theme-accent-color);
	}
}

@include from-md {
	.positioning-statement {
		font-size: var(--fs-big-heading);
	}
}
