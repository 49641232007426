.card {
	$c: &;
	display: grid;
	grid-column-gap: var(--page-gutter);
	grid-template-columns: repeat(2, 1fr);
	&--copy {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}
	&--title {
		@include base-heading;
		line-height: 1.5;
		font-size: var(--fs-subheading);
		transition: color 200ms ease-in;
		margin-top: $usual-font-offset;
	}
	&--metadata {
		line-height: 1.2;
		font-size: var(--fs-ui-sm);
		color: var(--theme-accent-color);
		text-transform: uppercase;
		margin-top: 0.5em;
		span + span {
			margin-left: 1em;
		}
	}
	&:hover,
	&:focus,
	&:active {
		color: var(--theme-accent-color);
	}

	.theme-fest23 & {
		&:hover,
		&:focus,
		&:active {
			#{$c}--title {
				color: var(--theme-text-color);
			}
		}
	}
}

@include from-md {
	.card {
		$c: &;

		display: flex;
		flex-direction: column;
		&--cover {
			order: 1;
			margin-bottom: var(--p-rythm);
			overflow: hidden;
			img {
				transition: transform 5s ease-out;
				transform: scale(1.0001);
				will-change: transform;
			}
		}
		&--copy {
			order: 2;
			display: flex;
			flex-direction: column;
		}
		&--metadata {
			margin-top: 0;
			margin-top: 0.2em;
		}
		&--title {
			width: 100%;
		}

		&:hover,
		&:focus,
		&:active {
			#{$c}--cover {
				img {
					transform: scale(1.07);
				}
			}
		}
	}
}

.theme-tailings {
	.card {
		&--title {
			display: inline;
			position: relative;
			&:after {
				transition: opacity 150ms ease-in-out;
				content: "";
				width: 100%;
				height: 2px;
				background: #333333;
				position: absolute;
				left: 0;
				bottom: calc(-1.5 * var(--p-rythm-sm));
				opacity: 0;
			}
		}

		&:hover,
		&:focus,
		&:active {
			.card--title {
				color: var(--theme-text-color);
				&:after {
					opacity: 1;
				}
			}
		}
	}
}
