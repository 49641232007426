*,
*:before,
*:after {
	box-sizing: border-box;
}

html {
	overflow-y: scroll;
}

@keyframes fadein {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

body {
	background: var(--theme-page-background-color);
	overflow: hidden;
	position: relative;
	&.nav-is-active,
	&.modal-is-open,
	&.map-is-active {
		height: 100vh;
	}
}

#barba-wrapper {
	position: relative;
	z-index: 10;
}

.svgsprite {
	svg path,
	svg polygon {
		transition: fill 300ms ease-in-out;
	}
}
