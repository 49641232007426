.blockquote {
	margin: 0;
	&--body {
		font-size: var(--fs-big-heading);
		font-style: italic;
	}
	&--citation {
		position: relative;
		margin-top: var(--p-rythm);
		font-size: var(--fs-subheading);
		padding-left: calc(var(--line-length) + var(--p-rythm-sm));
		&:before {
			content: "";
			position: absolute;
			top: 50%;
			width: var(--line-length);
			height: 2px;
			margin-top: -1px;
			background: var(--theme-text-color);
			left: 0;
		}
	}
}

@include from-md {
	.blockquote {
		--line-length: 200px;
	}
}
