.icon-list {
	$c: &;
	&--item {
		margin: 0;
		display: flex;
		align-items: center;
		.richtext {
			flex: 1;
		}
		&-icon {
			height: 40px;
			width: auto;
			display: block;
			color: var(--theme-text-color);
			margin-right: calc(var(--p-rythm-sm) / 2);
		}
	}

	&__stack {
		#{$c}--item {
			display: grid;
			grid-template-areas:
				"icon title"
				"text text";
			grid-template-columns: 60px 1fr;
			gap: calc(var(--p-rythm-sm) / 2);
			&-icon {
				grid-area: icon;
				margin-right: 0;
				margin-bottom: 0;
				height: 60px;
			}
			&-title {
				display: flex;
				align-items: center;
				grid-area: title;
			}
			.rich-text {
				grid-area: text;
			}
		}
		#{$c}--item + #{$c}--item {
			margin-top: var(--p-rythm);
		}
	}
}
