.l-notifications {
	transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
	position: fixed;
	bottom: 0;
	left: 0;
	width: var(--page-width);
	background: var(--theme-ui-background-color);
	color: white;
	transform: translateY(100%);
	z-index: $zi-layer-nav;
	padding: var(--p-rythm-sm) 0;
	opacity: 0;
	&.is-active {
		transform: translateY(0);
		opacity: 1;
	}
}

@include from-md {
	.l-notifications {
		left: 50%;
		transform: translateX(-50%) translateY(100%);
		&.is-active {
			transform: translateX(-50%) translateY(0);
		}
	}
}
