$control-size: 20px;

.form-choice-buttons {
	display: grid;
	grid-gap: var(--p-rythm-sm);
	grid-template-columns: repeat(2, 1fr);
	&__2x {
		grid-template-columns: 1fr;
	}

	&--input {
		grid-column: span 2;
		position: relative;
		margin-top: var(--p-rythm-sm);
	}

	// TODO: Could probably make some base mixins in bases and use them here and in form-field -JS
	&--boolean {
		$b-root: &;

		display: block;
		input {
			@include visuallyhidden;
			&:checked + #{$b-root}-label {
				background: var(--theme-accent-color);
				border-color: var(--theme-accent-color);
				color: var(--theme-page-background-color);
			}
		}
		&-label {
			transition: 200ms ease-in-out;
			position: relative;
			color: var(--theme-text-color);
			padding: var(--p-rythm-sm);

			line-height: 0.5;
			text-align: center;
			display: block;
			border: 2px solid var(--theme-text-color);
			&:hover {
				cursor: pointer;
				background: var(--theme-text-color);
				color: var(--theme-page-background-color);
			}
		}
	}
	&--error-messages {
		color: red;
		width: 100%;
		&-single {
			margin-top: 10px;
		}
	}
}

@include from($bp-medium) {
	.form-choice-buttons {
		grid-template-columns: repeat(3, 1fr);
		&__2x {
			grid-template-columns: repeat(2, 1fr);
		}
		&--error-messages,
		&--input {
			grid-column: span 3;
		}
		&--boolean {
			&-label {
				font-size: var(--fs-subheading);
			}
		}
	}
}
