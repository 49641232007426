.countdown {
	--section-size: 15vw;
	&--timer,
	&--labels {
		display: flex;
		justify-content: space-between;
	}
	&--timer {
		span {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: min(5.2vw, 100px);
			width: var(--section-size);
			height: var(--section-size);
			border: 2px solid var(--theme-accent-color);
		}
	}
	&--labels {
		span {
			width: var(--section-size);
			text-align: center;
			margin-top: 0.4em;
		}
	}
	&--title {
		font-size: var(--fs-heading);
		margin-bottom: var(--p-rythm);
	}
}

@include from-md {
	.countdown {
		--section-size: 9.3vw;
		&--timer,
		&--labels {
			justify-content: flex-start;
			span + span {
				margin-left: var(--p-rythm-sm);
			}
		}
		&--labels {
			span {
				font-size: var(--fs-subheading);
			}
		}
		&--title {
			font-size: var(--fs-big-heading);
		}
	}
}
