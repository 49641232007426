.add-button {
	transition: all 300ms ease-in-out;
	background: var(--theme-ui-background-color);
	width: 23px;
	height: 23px;
	padding: 5px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	margin-left: 5px;
	svg {
		transition: transform 300ms ease-in-out;
		width: 15px;
		height: 15px;
		backface-visibility: hidden;
		color: var(--theme-accent-color);
	}

	&:focus,
	&:hover {
		background: var(--theme-accent-color);
		svg {
			color: var(--theme-ui-background-color);
		}
	}

	&.is-active {
		background: var(--theme-accent-color);
		svg {
			transform: rotate(45deg);
			color: var(--theme-ui-background-color);
		}
	}
}
