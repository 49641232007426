.l-cm-subscribe {
	$c: &;
	&--input-hpt {
		@include visuallyhidden();
	}
	&--input {
		font-size: var(--fs-subheading);
		position: relative;
		input {
			height: var(--form-control-height);
			border: 2px solid var(--theme-text-color);
			outline: 2px solid transparent;
			color: var(--theme-text-color);
			padding: var(--form-control-padding);
			background: none;
			width: 100%;
			padding-right: 7ch;
			&:active,
			&:focus {
				appearance: none;
				border-color: var(--theme-accent-color);
				outline-color: var(--theme-accent-color);
			}
			&::placeholder {
				color: var(--theme-text-color);
				opacity: 1;
				.theme-tailings & {
					color: var(--theme-accent-color);
				}
			}
		}
		button {
			position: absolute;
			right: var(--form-control-padding);
			top: 50%;
			transform: translateY(-50%);
			width: auto;
			&:hover {
				cursor: pointer;
			}
			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}

	.rich-text,
	&--title {
		margin-bottom: var(--p-rythm-sm);
	}

	&--message {
		margin: 0;
		.form-message {
			margin-top: $padding-rythm-sm;
		}
	}

	.theme-fest23 & {
		color: var(--theme-accent-color);
		#{$c}--input {
			input {
				border-color: var(--theme-accent-color);
				&::placeholder {
					color: var(--theme-accent-color);
				}
				&:focus {
					border-color: var(--theme-text-color);
					outline-color: var(--theme-text-color);
				}
			}
		}
	}
}
