.social-text-links {
	a {
		@include base-link;
		display: inline-block;
		width: auto;
		border-bottom-color: transparent;
		&:hover {
			border-bottom-color: var(--theme-accent-color);
		}
	}
	a + a {
		margin-left: 1.2em;
	}
}
