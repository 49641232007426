.l-page-copy {
	margin-top: var(--p-rythm);
	&--title {
		font-size: var(--fs-heading);
		margin-top: $usual-font-offset;
		+ .rich-text {
			margin-top: var(--p-rythm-sm);
		}
	}
	&--subtitle {
		font-size: var(--fs-subheading);
		margin-top: var(--p-rythm-sm);
	}
	&--meta {
		text-transform: uppercase;
		font-size: var(--fs-ui-sm);
		color: var(--theme-accent-color);
		margin-top: var(--p-rythm-sm);
		display: flex;
		align-items: center;
		span + span {
			margin-left: 1em;
		}
	}
	.meta-list {
		margin-top: var(--p-rythm);
	}
	&--aside {
		margin-bottom: var(--p-rythm);
		> .rich-text {
			margin-top: var(--p-rythm-sm);
		}
	}
	&--body {
		.block-image,
		.accordion,
		.richtext,
		.captioned-media,
		.l-form,
		.l-cm-subscribe,
		.scene-break,
		.icon-list,
		.calendly-embed,
		.rich-text {
			+ .block-image,
			+ .accordion,
			+ .richtext,
			+ .captioned-media,
			+ .l-form,
			+ .l-cm-subscribe,
			+ .scene-break,
			+ .icon-list,
			+ .calendly-embed,
			+ .rich-text {
				margin-top: var(--p-rythm);
			}
		}
	}
}

@include to-md {
	.l-page-copy {
		display: flex;
		flex-direction: column;
		&--aside {
			display: contents;
			> * {
				order: 1;
			}
			> .meta-list {
				order: 4;
			}
		}
		&--subtitle {
			margin-bottom: var(--p-rythm);
		}
		&--body {
			order: 3;
		}
	}
}

@include from($bp-medium) {
	.l-page-copy {
		display: grid;
		grid-template-columns: 2fr 2fr;
		grid-gap: var(--p-rythm);

		&--aside {
			margin-bottom: 0;
			padding-right: 25%;
		}
		&--body {
			.captioned-media__full-width {
				margin-left: calc((var(--page-width) - 100%) * -1);
			}
		}
	}
}
