.accordion {
	margin-bottom: calc(var(--p-rythm-sm) / 2 * -1);
	&--item {
		$i: &;

		&.is-active {
			#{$i}-trigger {
				svg {
					transform: rotate(-180deg);
				}
			}
		}

		&:first-child &-trigger {
			padding-top: 0;
		}

		&-trigger {
			padding: calc(var(--p-rythm-sm) / 2) 0;
			display: block;
			appearance: none;
			outline: none !important;
			position: relative;

			@include base-heading;
			font-size: var(--fs-subheading);

			padding-right: 80px;

			&-icon {
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				right: 8px;
				top: 50%;
				width: 20px;
				height: 20px;
				transform: translateY(-50%);
				transition: background-color 150ms ease-in-out;
				svg {
					transform: rotate(0);
					color: var(--theme-accent-color);
					width: auto;
					height: 20px;
					position: absolute;
					transition: all 150ms ease-in-out;
				}
			}

			&:hover {
				color: var(--theme-accent-color);
				cursor: pointer;
			}
		}

		&:last-child &-content {
			padding-bottom: 0;
		}
		&-content {
			overflow: hidden;
			transition: height 250ms ease-out;
			height: var(--accordion-height);
			padding-bottom: var(--p-rythm-sm);

			&.is-closed {
				height: 0;
				padding: 0;
			}
		}
	}

	&--item {
	}

	@include from-md {
		&--item {
			&-trigger {
				&-icon {
				}
			}
		}
	}
}

@media print {
	.accordion {
		&--item {
			&-content {
				margin-top: 1em;
				&.is-closed {
					height: auto;
				}
			}
			&-trigger {
				padding: 0;
				margin-top: 2em;
				&:hover {
					color: black;
					cursor: default;
				}
				&-icon {
					display: none;
				}
			}
		}
	}
}
