.l-site-content {
	width: var(--page-width);
	margin: 0 auto;
	padding-bottom: var(--p-rythm);

	&--section {
		position: relative;
		z-index: 2;
		margin-bottom: var(--section-gap);
		&:last-child,
		&__no-gap {
			margin-bottom: 0;
		}
		&__p-rythm-gap {
			margin-bottom: var(--p-rythm);
		}
		&__half-gap {
			margin-bottom: calc(var(--section-gap) / 2);
		}
		&__no-gutter {
			z-index: 1;
			position: relative;
			margin-left: calc(var(--page-gutter) * -1);
			margin-right: calc(var(--page-gutter) * -1);
			margin-top: calc(var(--header-height) * -1);
			margin-bottom: var(--p-rythm);
		}
		&__tailings-header {
			margin-bottom: calc(var(--section-gap) / 2);
			.featured-article {
				width: var(--page-width);
				margin: 0 auto;
				margin-top: calc(
					var(--p-rythm) * 0.73
				); // Adjusted for title line height
			}
		}

		&__pad-for-header {
			padding-top: var(--header-height);
		}
	}
}

@include to($bp-medium) {
	.l-site-content {
		&--section {
			&__tailings-header {
				.featured-article {
					margin-top: var(--p-rythm-sm);
					&--title {
						font-size: var(--fs-subheading);
						line-height: 1;
						margin-top: calc(var(--p-rythm-sm) / 2);
					}
				}
				.block-image--item {
					height: 70vh;
					max-height: 510px;
					img {
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}
	}
}
