.l-home-fold {
	&--logo {
		height: 30vh;
		display: flex;
		align-items: center;
		&-img {
			width: 100%;
			height: auto;
		}
	}
	.positioning-statement {
		margin-bottom: var(--section-gap);
	}
}

@include to-md {
	.l-home-fold {
		.positioning-statement {
			margin-bottom: calc(var(--section-gap) / 2);
		}
	}
}

@include from-md {
	.l-home-fold {
		&--logo {
			height: calc(100vh - 1.5 * var(--header-height));
			max-height: 1030px;
			&-img {
				width: 70%;
				margin: 0 auto;
			}
		}
		.positioning-statement {
			margin-top: var(--p-rythm-lg);
		}
	}
}
