@media print {
	.l-site-header .l-navigation,
	.l-site-header--trigger,
	.l-site-header:after,
	.l-background,
	#contact,
	.l-site-footer {
		display: none;
	}

	body {
		--fs-body: 11pt;
		color: black;
		background: white;
	}

	.l-site-header--logo svg * {
		color: black;
	}

	.l-page-copy {
		&--title {
			margin-top: 0;
		}
		&--body {
			--p-rythm: 1.2em;
		}
	}
}
