$width: 48px;
$height: 20px;

.boolean-pill {
	user-select: none;
	&:hover {
		cursor: pointer;
	}
	input {
		display: none;
		&:checked + label {
			&:after {
				right: 0;
			}
			&:before {
				background: var(--theme-accent-color);
			}
		}
	}
	&--label {
		padding-right: $width + $padding-rythm-sm;
		position: relative;
		display: block;
		&:after,
		&:before {
			transition: all 100ms ease-in-out;
			position: absolute;
			content: "";
			top: 50%;
			transform: translateY(-50%);
			border-radius: $height;
		}
		&:before {
			right: 0;

			width: $width;
			height: $height;
			background: var(--theme-ui-background-color-light);
		}
		&:after {
			right: $width - $height;
			width: $height;
			height: $height;
			background: white;
		}
		&:hover {
			cursor: pointer;
		}
	}
}

$width: 55px;
$height: 25px;

@include from($bp-medium) {
	.boolean-pill {
		&--label {
			display: block;
			padding-right: $width + $padding-rythm-sm;
			&:before {
				right: 0;

				width: $width;
				height: $height;
			}
			&:after {
				right: $width - $height;
				width: $height;
				height: $height;
			}
		}
	}
}
