$transitionUnit: 300ms;

.l-site-content,
.l-sitewide-banner,
.l-site-footer,
.l-site-header {
	transition: opacity $transitionUnit ease-in-out;
	// When transitioning back in wait for .l-background:before
	// to be 1/3rd through its transition before starting this one
	transition-delay: $transitionUnit * 2;
}

// Some transitions shouldnt be invoked until the page has loaded
.page-has-loaded {
	.l-background:before {
		transition: opacity $transitionUnit * 3 ease-in-out;
	}
}

.is-transitioning {
	.l-background:before,
	.l-site-content,
	.l-sitewide-banner,
	.l-site-footer,
	.l-site-header {
		opacity: 0;
		transition-delay: 0ms;
	}
}
