.section-header {
	$c: &;

	width: 100%;
	padding-bottom: var(--p-rythm);
	&--title {
		@include base-heading;
		line-height: 0.8;
		font-size: var(--fs-heading);
	}
	&--link {
		margin-top: var(--p-rythm-sm);
		width: auto;
	}

	&__large {
		padding-bottom: 0;
		#{$c}--title {
			font-size: var(--fs-gargantuan-heading);
			margin: 1.375em 0 1.5em 0;
		}
	}
}

@include from-md {
	.section-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		&--link {
			margin-top: 0;
		}
	}
}
