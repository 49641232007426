body {
	font-size: var(--fs-body);
	font-family: $body-font;
	line-height: 1.4;
	color: var(--theme-text-color);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

::selection {
	background: var(--theme-accent-color);
	color: var(--theme-page-background-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include base-heading;
}

ul,
li {
	margin: 0;
}

img {
	width: 100%;
	height: auto;
}

p {
	margin: 0;
}

.time-list {
	list-style: none;
	padding: 0;
	margin: 0;
	li + li {
		margin-top: 0.5em;
	}
}

.richtext,
.rich-text {
	> * + * {
		margin-top: 0.4em;
	}
	* + h1,
	* + h2,
	* + h3,
	* + h4 {
		margin-top: 2em;
	}

	p + p {
		margin-top: 1.2em;
	}

	*:first-child {
		margin-top: 0;
	}

	*:last-child {
		margin-bottom: 0;
	}

	h2,
	h3,
	h4 {
		font-size: var(--fs-subheading);
		line-height: 1.2;
		+ p,
		+ .time-list {
			margin-top: 1em;
		}
	}

	h3 + h4 {
		margin-top: 1em;
	}

	h4 {
		font-size: var(--fs-body);
		font-weight: bold;
	}

	p {
		line-height: 1.75;
	}

	ol:not([class]),
	ul:not([class]) {
		margin: 1em 0;
		margin-left: 1.5em;
		ol,
		ul {
			margin-top: 0.4em;
			margin-bottom: 0.4em;
			padding-left: 1.5em;
		}
		li {
			list-style-position: outside;
		}
		li + li {
			margin-top: 0.4em;
		}
	}

	ol:not([class]) {
		list-style: decimal;
		list-style-position: inside;
	}

	ul:not([class]),
	ul:not([class]) li {
		list-style: disc;
		list-style-type: "— ";
	}

	ol:not([class]) li {
		display: list-item;
	}

	ol > li::marker {
		font-weight: bold;
	}

	.inline-type-svg {
		display: inline-block;
		transform: translateY(1.5px);
		margin: 0 4px;
		svg {
			display: block;
			width: 15px;
			height: 15px;
		}
	}

	a:not([class]) {
		@include base-link;
		display: inline;
	}

	.responsive-object {
		position: relative;
		width: 100%;
		iframe,
		img {
			position: absolute;
			height: 100%;
			width: 100%;
		}
	}
}

@include from($bp-medium) {
	.richtext,
	.rich-text {
		> * + * {
			margin-top: 1em;
		}
		*:first-child {
			margin-top: 0;
		}
		*:last-child {
			margin-bottom: 0;
		}
		.richtext-image.left,
		.richtext-image.right {
			width: 50%;
		}
		.richtext-image.right {
			margin-left: auto;
		}
	}
}

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
