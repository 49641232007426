$progranm-row-height: 130px;
$progranm-row-height-small: 60px;
.l-program {
	display: none;
	&.is-active {
		display: block;
	}
	&--slot {
		margin-bottom: var(--p-rythm-sm);
		.program-card,
		.tile {
			margin-bottom: $micro-gap-size;
		}
	}
}

@include from($bp-small) {
	.l-program {
		margin-top: -22px; // ew.
		&--slot {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			margin-bottom: calc(var(--p-rythm-sm) / 2);
			padding-left: $progranm-row-height;
			min-height: $progranm-row-height-small + $micro-gap-size;

			&:last-child {
				margin-bottom: 0;
			}

			.tile {
				position: absolute;
				top: 0;
				left: 0;
				width: $progranm-row-height;
				height: 100%;
				max-height: $progranm-row-height;
			}

			.program-card {
				height: $progranm-row-height;
				margin-bottom: $micro-gap-size;
				margin-left: $micro-gap-size;
			}
		}
	}
}
