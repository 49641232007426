.content-cta {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-areas:
		"media"
		"body";
	grid-gap: var(--p-rythm);
	&--body {
		grid-area: body;
		&-title {
			margin: 0;
			margin-top: $usual-font-offset;
		}
		.rich-text {
			margin-top: var(--p-rythm);
		}
		&-links {
			margin-top: var(--p-rythm);
			.site-link + .site-link {
				margin-top: var(--p-rythm-sm);
			}
		}
	}

	&--media {
		grid-area: media;
		width: 100%;
		position: relative;
		align-self: start;
	}
}

@include to-md {
	.content-cta {
		&--body {
			font-size: var(--fs-body-lg);
		}
	}
}

@include from-md {
	.content-cta {
		grid-gap: 0;
		grid-template-columns: repeat(4, 1fr);
		grid-template-areas: "body media media media";
		&--body {
			padding-right: var(--p-rythm);
		}
	}
}
