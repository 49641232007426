.l-standard-page {
	position: relative;
	z-index: 2;
	margin-top: var(--p-rythm-lg);
	&--title {
		font-size: var(--fs-heading);
		margin-top: $usual-font-offset;
	}
	&--actions {
		background: var(--theme-ui-background-color);
		padding: var(--p-rythm-sm);
		margin-top: var(--p-rythm);
		.radio-link-list {
			margin-bottom: var(--p-rythm-sm);
		}
		.boolean-pill {
			margin-bottom: calc(var(--p-rythm-sm) / 2);
		}

		.theme-fest23 & {
			background: none;
			padding-left: 0;
			padding-right: 0;
		}
	}
	.l-program {
		margin-top: var(--p-rythm-sm);
	}
}

@include from($bp-tiny) {
	.l-standard-page {
		&--actions {
			display: flex;
			flex-wrap: wrap;
			.radio-link-list {
				flex: 1;
				flex-basis: 100%;
			}
			.boolean-pill {
				margin-right: $padding-rythm;
				margin-bottom: 0;
				.theme-fest23 & {
					margin-right: 0;
				}
			}
		}
	}
}

@include from($bp-medium) {
	.l-standard-page {
		&--actions {
			.radio-link-list {
				margin-bottom: 0;
				flex-basis: auto;
			}
			.boolean-pill {
				margin-left: auto;
			}
		}
	}
}
