@use "sass:math";

$size: 20px;

.radio-link {
	display: block;
	padding-right: var(--p-rythm-sm);
	position: relative;

	&:before,
	&:after {
		content: "";
		position: absolute;

		width: $size;
		height: $size;
		border-radius: 100%;
	}

	&:before {
		right: 0;
		top: 1px;
		border: 1px solid var(--theme-accent-color);
	}
	&:after {
		transition: opacity 200ms ease-in-out;
		right: 3px;
		top: 4px;
		width: $size - 6px;
		height: $size - 6px;
		background: var(--theme-accent-color);
		opacity: 0;
	}

	&:hover {
		&:after {
			opacity: 0.5;
		}
	}
	&.is-active {
		&:after {
			opacity: 1;
		}
	}

	&__underlined {
		transition: border-color $base-anim-rythm ease-in-out;
		padding-right: 0;
		padding-bottom: 8px;
		border-bottom: 2px solid transparent;
		&:before,
		&:after {
			content: none;
		}
		&:hover,
		&:focus,
		&.is-active {
			border-bottom-color: var(--theme-accent-color);
		}
	}
}

.radio-link-list {
	.radio-link + .radio-link {
		margin-top: calc(var(--p-rythm-sm) / 2);
	}
}

@include from($bp-tiny) {
	.radio-link {
		display: inline-block;
		width: auto;
		padding-right: 0;
		padding-left: var(--p-rythm-sm);
		&:before {
			right: auto;
			left: 0;
		}
		&:after {
			right: auto;
			left: 3px;
		}
		&__underlined {
			padding-left: 0;
		}
	}

	.radio-link-list {
		display: inline-block;
		margin-right: var(--p-rythm-sm);
		.radio-link + .radio-link {
			margin-top: 0;
			margin-left: var(--p-rythm-sm);
		}
	}
}
