.program-card {
	position: relative;
	display: flex;

	&.is-hidden {
		display: none;
	}

	&--add {
		transition: background 300ms ease-in-out;
		background: var(--theme-ui-background-color);
		width: 34px;
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			transition: transform 300ms ease-in-out;
			backface-visibility: hidden;
			width: 15px;
			height: 15px;
			color: var(--theme-accent-color);
			use {
				fill: var(--theme-accent-color);
			}
		}
		&:hover {
			use {
				fill: var(--theme-accent-color-light);
			}
		}
	}

	&--inner {
		transition: background 300ms ease-in-out;
		display: block;
		background: var(--theme-ui-background-color);
		padding: $padding-rythm-sm;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		color: $middle-grey;

		&:hover {
			.program-card--title {
				&:after {
					opacity: 1;
					transform: translateY(0);
				}
			}
		}

		.theme-a11y & {
			color: black;
		}
	}

	&--price,
	&--title,
	&--time,
	&--location {
		transition: color 300ms ease-in-out;
	}

	&--price,
	&--time,
	&--location {
		margin-top: 0.4em;
		display: block;
	}

	&--price {
		color: var(--theme-accent-color);
	}

	&--title {
		color: var(--theme-text-color);
		font-size: $fs-h2-sm;
		font-family: $body-font;
		margin-bottom: auto;
		display: inline-block;
		position: relative;
		&:after {
			position: absolute;
			content: "";
			transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
			width: 100%;
			height: 2px;
			background: var(--theme-accent-color);
			bottom: -5px;
			left: 0;
			opacity: 0;
			transform: translateY(3px);
		}
	}
	&--time {
	}
	&--location {
	}

	&--cover {
		display: none;
	}

	&.is-active {
		.program-card {
			&--add {
				background: var(--theme-ui-background-color);
				svg {
					transform: rotate(45deg);
				}
			}
			&--inner,
			&--cover {
				background: var(--theme-accent-color);
			}
			&--price,
			&--title,
			&--inner {
				color: black;
				.theme-a11y & {
					color: white;
				}
			}
			&--title {
				&:after {
					background: var(--theme-accent-color-light);
				}
			}
		}
	}
}

@include to($bp-medium) {
	.program-card {
		width: 100%;
		&--inner {
			flex-grow: 1;
		}
	}
}

@include from($bp-medium) {
	.program-card {
		position: relative;
		z-index: 1001;
		min-width: 325px;
		&--inner {
			position: relative;
			z-index: 2;
			padding: $padding-rythm-md;
			flex: 1;
		}

		&--add {
			position: relative;
			z-index: 2;
		}

		&--cover {
			transition: transform 200ms ease-in-out;
			transition-delay: 150ms;
			transform: scaleY(0);
			transform-origin: bottom;

			background: var(--theme-ui-background-color);

			display: block;
			pointer-events: none;
			width: calc(100% - 34px);
			height: 260px;

			z-index: 1;
			position: absolute;
			bottom: 100%;
			left: 0;
			overflow: hidden;

			&-image {
				transition: opacity 200ms ease-in-out;
				opacity: 0;
				width: 100%;
				height: 100%;
				display: block;
				object-fit: cover;
				backface-visibility: hidden;
				transform: translateZ(0);
			}
		}
		&:hover &--cover {
			transform: scaleY(1);
			&-image {
				transition-delay: 350ms;
				opacity: 1;
			}
		}
	}
}
