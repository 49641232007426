/**
 * Media Queries - *Try* and make everything fit within these - use @from/to/between
 */
$bp-tiny: 500px;
$bp-small: 768px;
$bp-medium: 1024px;
$bp-large: 1280px;
$bp-very-large: 1440px;

/**
 * Font definitions
 */
$defaultFontSize: 1;

$body-font: "usual", sans-serif;
$heading-font: "usual", sans-serif;

/**
 * Font Sizes
 */

$fs-h1: 35px;
$fs-h2: 30px;
$fs-h1-sm: 28px;
$fs-h2-sm: 20px;
$fs-body-large: 18px;
$fs-body: 16px;

/**
 * Transitions
 */
$base-anim-rythm: 300ms;
$transition-default: all $base-anim-rythm ease-in-out;

/**
 * Padding
 * Usage: padding: $padding-rythm*2
 */
$padding-rythm: 50px;
$padding-rythm-md: 30px;
$padding-rythm-sm: 20px;

/**
 * Raw Colors - Try not to use these within actual styles!
 */
$dark-grey: #404040;
$middle-grey: #c4c4c4;
$light-grey: #efeff0; // #eaebec

$accent-color: #d79f74;
$accent-color-light: lighten($accent-color, 18%);

/**
 * z-index stack
 */
$zi-layer-1: 20;
$zi-layer-2: 40;
$zi-layer-3: 60;
$zi-layer-4: 80;
$zi-layer-map: 150;
$zi-layer-map-inner: 151;
$zi-layer-nav: 200;
$zi-layer-map: 300;

/**
 * Misc
 */
$micro-gap-size: 6px;
$defaultFontSize: 1;
$transition-default: all 300ms ease-in-out;
$block-on-bg-shadow: 0 20px 32px -20px rgba(black, 0.5);
$block-on-bg-shadow-dark: 0 5px 25px 5px rgba(0, 0, 0, 0.5);
$usual-font-offset: -0.2em;

:root {
	// Base theme
	--theme-text-color: #{$middle-grey};
	--theme-accent-color: #{$accent-color};
	--theme-accent-color-light: #{$accent-color-light};
	--theme-page-background-color: #000000;
	--theme-ui-background-color: #{$dark-grey};
	--theme-ui-background-color-light: #{lighten($dark-grey, 18%)};
	--theme-page-top-gradient: #{linear-gradient(to bottom, black, rgba(black, 0))};
	--theme-logo-color: white;
}

body.theme-a11y,
body.theme-tailings {
	--theme-text-color: #333333;
	--theme-accent-color: #999999;
	--theme-accent-color-light: #999999;
	--theme-page-background-color: #ffffff;
	--theme-ui-background-color: #ffffff;
	--theme-page-top-gradient: #{linear-gradient(to bottom, white, rgba(white, 0))};
	--theme-logo-color: black;
}

body.theme-a11y {
	--theme-text-color: #000;
	--theme-accent-color: #000;
}

:root {
	--p-rythm: 45px;
	--p-rythm-sm: calc(var(--p-rythm) / 2);
	--p-rythm-lg: calc(var(--p-rythm) * 2);
	--section-gap: 150px;
	--page-gutter: min(3.69vw, 75px);
	--page-width: min(calc(100vw - var(--page-gutter) * 2), 1790px);
	--header-height: calc(2 * var(--page-gutter) + 40px);

	--fs-gargantuan-heading: 60px;
	--fs-block-text-overlay: 50px;
	--fs-big-heading: 35px;
	--fs-heading: 26px;
	--fs-subheading: 20px;
	--fs-body-lg: 18px;
	--fs-body: 16px;
	--fs-ui: 15px; // Also replaces any instance of 14px in designs
	--fs-ui-sm: 13px;
	--fs-text-logo: 30px;
	--fs-menu-text: 14px;

	--form-control-height: 44px;
	--form-control-padding: 10px;
}

@include from-md {
	:root {
		--p-rythm: min(5vw, 50px);
		--section-gap: min(20vw, 385px);

		--header-height: 115px;

		--fs-gargantuan-heading: min(11vw, 160px);
		--fs-block-text-overlay: min(5vw, 80px);
		--fs-big-heading: min(4.16vw, 60px);
		--fs-heading: min(2.7vw, 35px);
		--fs-subheading: min(1.6vw, 25px);
		--fs-text-logo: min(3.47vw, 50px);

		--form-control-height: 80px;
		--form-control-padding: 20px;
	}
}

@include from-lg {
	:root {
		--fs-menu-text: 15px;
	}
}
@include from($bp-very-large) {
	:root {
		--p-rythm: min(5vw, 64px);
		--page-gutter: min(5vw, 64px);

		--fs-gargantuan-heading: 160px;
		--fs-big-heading: 60px;
		--fs-heading: 40px;
		--fs-subheading: 25px;
		--fs-body: 20px;
		--fs-text-logo: 50px;

		--form-control-height: 80px;
		--form-control-padding: 20px;
	}
}

@include from(1920px) {
	:root {
		--fs-block-text-overlay: 80px;
	}
}
