.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: var(--theme-ui-background-color);
	padding: $padding-rythm-md;
	padding-top: $padding-rythm * 2;
	z-index: 10000;

	&--close {
		position: absolute;
		display: block;
		top: $padding-rythm-sm;
		right: $padding-rythm-sm;
		svg {
			width: 18px;
			height: 18px;
			use {
				fill: var(--theme-accent-color);
			}
		}
		&:hover {
			use {
				fill: var(--theme-accent-color-light);
			}
		}
	}

	&--form {
		margin-bottom: $padding-rythm-md;
	}
	&--copy {
		margin-bottom: $padding-rythm-md;
		h2 {
			color: white;
			font-size: $fs-h1-sm;
			margin-bottom: $padding-rythm-md;
		}
	}

	transition: opacity 300ms ease-in-out;
	opacity: 0;
	pointer-events: none;
	&.is-active {
		opacity: 1;
		pointer-events: all;
	}
}

@include from(550px) {
	.modal {
		padding-right: 30%;
	}
}

@include from($bp-medium) {
	.modal {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 10% calc((100% - 700px) / 2);
	}
}
