@use "sass:math";

.map-overlay {
	width: 100%;
	background: var(--theme-ui-background-color);
	position: relative;
	top: 0;
	left: 0;
	overflow: hidden;

	&--map {
		background: rgba(0, 0, 0, 0.6);
		position: relative;
		height: 45vh; //have to set for mobile when orientation changes
		overflow: hidden;
		&-inner {
			height: 100%;
		}
	}

	&--inner {
		width: 100%;
		max-height: 50%;
		min-height: 50%; //have to set for mobile when orientation changes
		z-index: $zi-layer-map-inner;

		display: grid;
		grid-template-areas: "main";
	}

	&--title {
		font-size: var(--fs-heading);
		margin-bottom: calc(var(--p-rythm-sm) / 2);
	}

	&--aside {
		background: var(--theme-ui-background-color);
		padding: var(--p-rythm-sm);
		overflow: hidden;
		display: flex;
		grid-area: main;
	}

	&--overview {
		overflow: hidden;
		display: flex;
		flex-flow: column nowrap;
		height: auto;
		min-width: 100%;

		opacity: 1;
		transition: opacity 0.3s ease-in-out;

		&.event-detail-active {
			opacity: 0;
		}

		.radio-link-list {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: var(--p-rythm-sm);
			margin-right: 0;
			.radio-link + .radio-link {
				margin-top: 0;
				margin-left: 0;
			}
		}
	}

	&--details {
		opacity: 0;
		min-width: 100%;
		grid-area: main;

		transition: opacity 0.3s, transform 0.3s;
		transform: translateX(100%);
		pointer-events: none;

		display: flex;
		flex-flow: column nowrap;
		overflow: hidden;
		padding: var(--p-rythm-sm);

		&.event-detail-active {
			pointer-events: all;
			opacity: 1;
			transform: translateX(0%);
		}
	}

	&--link {
		display: block;
		margin-bottom: var(--p-rythm-sm);
		margin-left: -6px;
		&:before {
			border: solid var(--theme-accent-color);
			border-width: 2px 0 0 2px;
			display: inline-block;
			margin: 0 10px 2px 8px;
			transform: rotate(-45deg);
			transition: transform 0.1s ease-in;
			width: 8px;
			height: 8px;
			content: "";
		}

		&:hover,
		&:active,
		&:focus {
			&:before {
				transform: rotate(-45deg) translateX(-4px) translateY(-4px);
			}
		}
	}

	&--events,
	&--venues {
		&-item {
			margin-top: calc(var(--p-rythm-sm) / 2);
			display: block;

			&:hover,
			&:focus,
			&:active {
				cursor: pointer;
			}

			&-inner {
				@include base-link;
				display: inline;
			}
		}
	}

	&--venues {
		margin-top: $padding-rythm-md;
		display: none;
		&.is-active {
			display: block;
		}
	}

	&--events {
		&-single {
			display: none;

			&.venue-active {
				display: block;
			}

			h3 {
				margin-top: var(--p-rythm-sm);
			}
		}
	}

	.radio-link {
		&[data-type="map-festival"],
		&[data-type="map-art-trail"] {
			position: relative;
			padding-left: 20px;
			&:before {
				content: "";
				display: inline-block;
				width: 14px;
				height: 14px;
				border-radius: 50%;
				border: 0 none;
				right: auto;
				left: 0;
			}
		}
		&[data-type="map-art-trail"] {
			&:before {
				background: white;
			}
		}
		&[data-type="map-festival"] {
			&:before {
				background: var(--theme-accent-color-light);
			}
		}
	}
}

.offscreet-marker {
	position: relative;
	&--arrow {
		width: 20px;
		height: 20px;
		transform-origin: center 60%;
	}
	&--label {
		position: absolute;
		right: calc(100% + 5px);
		top: 50%;
		transform: translateY(-50%);
		display: block;
		max-width: 110px;
		width: 100vw;
		font-size: 12px;
		font-weight: bold;
		padding: 2px 1px;
		text-shadow: var(--theme-ui-background-color) 1px 0 5px;
		&__right {
			right: auto;
			left: calc(100% + 5px);
		}
	}
}

.gm-style {
	cursor: pointer;
}

@include from($bp-small) {
	.map-overlay {
		&--venues {
			&:after {
				bottom: 40px;
			}
		}
	}
}

@include to($bp-medium) {
	.map-overlay {
		&--overview {
		}
	}
}

@include from($bp-medium) {
	.map-overlay {
		display: grid;
		grid-template-columns: 1fr 430px;
		height: 80vh;

		&--map {
			width: 100%;
			height: 100%;
		}

		&--inner {
			margin: 0;

			bottom: 0;
			right: 0;
			width: 430px;
			height: auto;
			max-height: 100%;
		}

		&--aside {
			height: auto;
		}

		&--venues {
			overflow-y: auto;
		}
	}
}
