@font-face {
	font-family: "industry";
	src: url("../fonts/industry-normal-400.woff2") format("woff2"),
		url("../fonts/industry-normal-400.woff") format("woff"),
		url("../fonts/industry-normal-400.ttc") format("opentype");
	font-display: swap;
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "industry";
	src: url("../fonts/industry-italic-400.woff2") format("woff2"),
		url("../fonts/industry-italic-400.woff") format("woff"),
		url("../fonts/industry-italic-400.ttc") format("opentype");
	font-display: swap;
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: "usual";
	src: url("../fonts/usual-normal-400.woff2") format("woff2"),
		url("../fonts/usual-normal-400.woff") format("woff"),
		url("../fonts/usual-normal-400.ttc") format("opentype");
	font-display: swap;
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "usual";
	src: url("../fonts/usual-italic-400.woff2") format("woff2"),
		url("../fonts/usual-italic-400.woff") format("woff"),
		url("../fonts/usual-italic-400.ttc") format("opentype");
	font-display: swap;
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: "usual";
	src: url("../fonts/usual-italic-700.woff2") format("woff2"),
		url("../fonts/usual-italic-700.woff") format("woff"),
		url("../fonts/usual-italic-700.ttc") format("opentype");
	font-display: swap;
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: "usual";
	src: url("../fonts/usual-normal-700.woff2") format("woff2"),
		url("../fonts/usual-normal-700.woff") format("woff"),
		url("../fonts/usual-normal-700.ttc") format("opentype");
	font-display: swap;
	font-style: normal;
	font-weight: 700;
}
