.meta-list {
	&--title {
		text-transform: uppercase;
		font-size: var(--fs-ui-sm);
		color: var(--theme-accent-color);
	}
	&--detail {
		margin: 0;
		margin-top: 0.8em;
		margin-bottom: var(--p-rythm);

		> * + * {
			margin-top: 1.2em;
		}
		> li + li {
			margin-top: 0.45em;
		}

		a:not([class]) {
			@include base-link;
			width: auto;
			display: inline;
			padding-bottom: 3px;
		}
		.rich-text {
			.left {
				// Deal with Sponsor logos that have been added to the Credits section on Event pages.
				width: 70%;
			}
		}
	}
}

@include from-md {
	.meta-list {
		&--detail {
			> a + a {
				margin-left: 1em;
			}
		}
	}
}
