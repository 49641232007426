// Promite all of the content over the background to a new layer. Helps with jank.
// Related to the background so I put it in this file -JS
#barba-wrapper {
	transform: translateZ(0);
	backface-visibility: hidden;
}

.l-background {
	will-change: transform;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	z-index: -1;
	pointer-events: none;
	&--asset-desktop,
	&--asset-mobile {
		transform: translateY(0%) translateZ(0);
		width: 100%;
		height: auto;
		display: block;
	}

	&--asset-desktop {
		display: none;
	}

	&:before {
		content: "";
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		background: rgba(0, 0, 0, 0.5);
		z-index: 1;
		opacity: 0;
		transform: translateZ(0);
		backface-visibility: hidden;
	}
	&:after {
		content: "";
		width: 102%;
		height: 80vh;
		bottom: -1px;
		left: 50%;
		transform: translateX(-51%);
		position: absolute;
		background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
		z-index: 10;
	}

	.has-minimal-image &,
	.has-dark-background & {
		&:before {
			opacity: 1;
		}
	}

	.theme-tailings & {
		display: none;
		&:before,
		&:after {
			content: "";
		}
	}
}

@keyframes parallax {
	to {
		transform: translate3d(0px, -10%, 0px);
	}
}

@include from($bp-medium) {
	.l-background {
		animation: parallax 1s linear;
		/* Pause the animation */
		animation-play-state: paused;
		/* Bind the animation to scroll */
		animation-delay: calc(var(--scroll) * -1s);
		/* These last 2 properites clean up overshoot weirdness */
		animation-iteration-count: 1;
		animation-fill-mode: both;
		&:before {
			background: rgba(0, 0, 0, 0.7);
		}
		&--asset-desktop {
			display: block;
		}
		&--asset-mobile {
			display: none;
		}
	}
}
