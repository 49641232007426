.menu-icon {
	position: relative;
	span {
		transition: 0.25s ease-in-out;
		display: block;
		background: var(--theme-text-color);
	}

	&--hamburger,
	&--cross {
		position: absolute;
		height: 100%;
		width: 100%;
	}

	&--hamburger {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		span {
			width: 100%;
			height: 2px;
			position: relative;
			top: 0;
			left: 0;
			&:nth-child(1) {
				transition-delay: 0.5s;
			}
			&:nth-child(2) {
				transition-delay: 0.625s;
			}
			&:nth-child(3) {
				transition-delay: 0.75s;
			}
		}
	}

	&--cross {
		position: absolute;
		height: 100%;
		width: 100%;
		transform: rotate(45deg);
		span {
			position: absolute;
			&:nth-child(1) {
				height: 0%;
				width: 2px;
				bottom: 0;
				left: calc(50% - 1px);
				transition-delay: 0s;
			}
			&:nth-child(2) {
				width: 0%;
				height: 2px;
				left: 0;
				top: calc(50% - 1px);
				transition-delay: 0.25s;
			}
		}
	}

	&.is-active .menu-icon {
		&--hamburger {
			span {
				width: 0%;
				&:nth-child(1) {
					transition-delay: 0s;
				}
				&:nth-child(2) {
					transition-delay: 0.125s;
				}
				&:nth-child(3) {
					transition-delay: 0.25s;
				}
			}
		}
		&--cross {
			span {
				background: var(--theme-text-color);
				&:nth-child(1) {
					height: 100%;
					transition-delay: 0.625s;
				}
				&:nth-child(2) {
					width: 100%;
					transition-delay: 0.375s;
				}
			}
		}
	}
}
