.site-link {
	$c: &;

	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: var(--fs-subheading);
	width: auto;
	&--inner {
		transition: color 200ms ease-in;
		line-height: 1.5;
		padding: 3px 0;
	}

	&--arrow {
		position: relative;
		width: 200px;
		height: 20px;
		margin-left: var(--p-rythm);
		margin-top: 0.2em;
		&:before,
		&:after {
			content: "";
			position: absolute;
			top: 50%;
		}
		&:before {
			height: 2px;
			margin-top: -1px;
			width: 100%;
			background: var(--theme-accent-color);
			left: 0;
		}
		&:after {
			transition: transform 100ms ease-in;
			content: "";
			position: absolute;
			top: 50%;
			right: 0;
			margin-top: -6px;
			width: 12px;
			height: 12px;
			border-right: 2px solid var(--theme-accent-color);
			border-top: 2px solid var(--theme-accent-color);
			transform: rotate(45deg);
		}
	}

	&:focus,
	&:active,
	&:hover,
	&.is-active {
		color: var(--theme-accent-color);
	}

	&__simple {
		#{$c}--arrow {
			--p-rythm: 20px;
			width: 10px;
			&:before {
				content: none;
			}
		}
	}

	&__reversed {
		flex-direction: row-reverse;
		#{$c}--arrow {
			margin-right: var(--p-rythm);
			margin-left: 0;
			&:after {
				right: auto;
				left: 0;
				transform: rotate(-135deg);
			}
		}
	}

	&__underlined-prominent,
	&__underlined {
		position: relative;
		padding-bottom: 0.5em;
		#{$c}--arrow {
			width: 100%;
			position: absolute;
			bottom: -10px;
			left: 0;
			margin-left: 0;
		}
	}
	&__underlined-prominent {
		padding-bottom: 2.2em;
		line-height: 1.3;
		font-size: var(--fs-heading);
	}

	// TODO: Make "visit unco" link use unco accent color not underline
	.theme-tailings & {
		--theme-accent-color: #000000;

		#{$c}--inner {
			transition: background-color 250ms ease-in-out;
			display: inline;
			border-bottom: 2px solid transparent;
			border-top: 2px solid transparent;
		}

		&:hover,
		&:focus,
		&:active {
			#{$c}--inner {
				border-bottom-color: 2px solid var(--theme-accent-color);
			}
		}
	}

	.theme-fest23 & {
		#{$c}--inner {
			color: var(--theme-accent-color);
		}

		&:hover,
		&:focus,
		&:active {
			#{$c}--inner {
				color: var(--theme-text-color);
			}
		}
	}
}

@include to-md {
	.site-link {
		$c: &;
		&__underlined-mobile-only {
			position: relative;
			padding-bottom: 0.5em;
			#{$c}--arrow {
				width: 100%;
				position: absolute;
				bottom: -10px;
				left: 0;
				margin-left: 0;
			}
		}
	}
}

@include from-md {
	.site-link {
		&--inner {
			display: block;
		}
	}
}
