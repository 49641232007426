// The tito CSS is loaded after all of our CSS, so there is a lot of !important here
.l-tito-widget-container {
	.tito-discount {
		display: none;
	}
	&__show-donations {
		.tito-discount {
			margin-top: calc(var(--section-gap) / 4) !important;
			display: block;
			+ .tito-submit {
				margin-top: calc(var(--section-gap) / 4) !important;
			}
		}
	}
}

.tito-widget {
	color: var(--theme-text-color) !important;
	justify-content: stretch !important;
	margin: 0 !important;

	a,
	button {
		width: auto;
	}

	.tito-widget-form {
		border: 0 none !important;
		padding: 0 !important;
		background: none !important;
		max-width: none !important;
		width: 100% !important;
	}

	.tito-form-actions {
		padding: 0 !important;

		.tito-submit {
			margin: 0 !important;
			margin-top: calc(var(--section-gap) / 2) !important;

			box-shadow: none !important;
			outline: none !important;
			appearance: none !important;
			border-radius: 0 !important;

			transition: 200ms ease-in-out !important;
			position: relative !important;
			color: var(--theme-text-color) !important;
			padding: var(--p-rythm-sm) !important;
			font-size: var(--fs-subheading) !important;
			line-height: 0.5 !important;
			text-align: center !important;
			display: block !important;
			border: 2px solid var(--theme-accent-color) !important;
			background: transparent !important;
			width: 100% !important;
			height: auto !important;

			&:hover,
			&:focus {
				background: var(--theme-accent-color) !important;
				color: var(--theme-page-background-color) !important;
			}
		}
	}

	.tito-choose-waiting-list-button {
		color: var(--theme-text-color) !important;
		background: none !important;
		border: 1px solid var(--theme-accent-color) !important;
		border-radius: 0 !important;
	}

	.tito-release {
		border: 0 none !important;
		&--tax {
			color: var(--theme-text-color) !important;
		}
		.tito-release-description .tito-release-description--description p {
			color: var(--theme-text-color) !important;
		}
	}

	.tito-quantity {
		a:not(.tito-release--increment-quantity--link--disabled) svg {
			color: var(--theme-accent-color) !important;
		}
		a.tito-release--increment-quantity--link--disabled {
			cursor: not-allowed;
		}
	}

	.tito-group {
		&--summary {
			list-style-type: none;
			margin-bottom: 20px;
			&::-webkit-details-marker,
			&::marker {
				display: none;
			}
			&-inner {
				width: 100%;
				position: relative;
				display: flex;
				align-items: center;
				padding-right: var(--p-rythm);
				svg {
					color: var(--theme-accent-color);
					position: absolute;
					right: 0;
					top: 50%;
					margin-top: -4px;
					width: 14px;
					height: 8px;
				}
			}
			&-title {
				@include base-heading;
				transition: color 200ms ease-in;
				order: 2;
				line-height: 1.5;
				font-size: var(--fs-subheading);
				// padding-left: var(--p-rythm-sm);
			}
			&-img {
				margin-right: var(--p-rythm-sm);
				order: 1;
				width: 150px;
			}

			&:hover,
			&:focus {
				color: var(--theme-accent-color) !important;
				cursor: pointer;
			}
		}
		&[open] {
			.tito-group--summary svg {
				transform: rotate(180deg);
			}
		}
	}

	.tito-group + .tito-group {
		margin-top: var(--p-rythm);
	}
}
