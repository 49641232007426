.map-pin {
	transition: 300ms ease-in-out;
	width: 0;
	height: 0;

	&--inner {
		transition: 300ms ease-in-out;
		position: absolute;
		bottom: 12px;
		left: 0;

		padding: 14px;
		min-width: 120px;

		transform: translateX(-26px);
		background: white;
		border: 3px solid black;
		opacity: 0;
		pointer-events: none;
		z-index: 11;
	}
		&--title {
			margin-bottom: $padding-rythm * 0.5;
		}
		&--website {
			@include base-link;
		}

	svg {
		width: 18px;
		height: auto;
		position: absolute;
		bottom: 100%;
		left: 50%;
		transform: translateX(-50%);
		transition: 300ms ease-in-out;
		color: black;
	}

	&:hover {
		position: relative;
		z-index: 10;
		.map-pin--inner {
			opacity: 1;
			pointer-events: all;
		}
	}
}