.l-site-footer {
	$c: &;
	width: var(--page-width);
	margin: 0 auto;
	margin-top: var(--section-gap);
	padding-bottom: var(--p-rythm);
	.sponsor-list + .sponsor-list {
		margin-top: var(--p-rythm-lg);
	}

	&--acknowledgement {
		font-size: var(--fs-subheading);
		margin-top: var(--p-rythm-lg);
	}
	&--credits {
		> * + * {
			margin-top: var(--p-rythm);
		}
		> *:first-child {
			// This element can be empty, in which case we don't want a margin so only add a top
			// margin if there is a first element. It's much less code to do this rather than a
			// really verbose template if/else/if etc. -JS
			margin-top: var(--p-rythm-lg);
		}
		.boolean-pill {
			display: inline-block;
		}
	}

	.theme-fest23 & {
		#{$c}--acknowledgement {
			color: var(--theme-accent-color);
		}
	}

	.theme-a11y & {
		.sponsor-list {
			background: var(--theme-text-color);
			padding: var(--p-rythm-sm);
		}
		.sponsor-list + .sponsor-list {
			margin-top: 0;
			padding-top: var(--p-rythm);
		}
	}
}
